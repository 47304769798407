import React from 'react'
import styles from './Footer.module.scss'

export default ({ twitter, medium }) => (
  <div className={styles.Footer}>
    <div className={styles.FooterContent}>
      <div className={styles.Title}>
        We’re sector agnostic, have clients globally, and enjoy connecting with a diverse group of people.
      </div>
      <p>
        <a href='mailto:hello@zero.london'>hello@zero.london</a>
      </p>

      <div className={styles.Title}>Location</div>
      <p>
        <a href='https://goo.gl/maps/f3GPfFD14YL2'>Uncommon, 1 Long Lane, London, SE1 4PG, United Kingdom</a>
      </p>

      <div className={styles.Title}>Follow us</div>
      <p>
        <a href={`https://twitter.com/${twitter}`} target='_blank' rel='noopener noreferrer'>
          Twitter
        </a>
        ,{' '}
        <a href={`https://medium.com/${medium}`} target='_blank' rel='noopener noreferrer'>
          Medium
        </a>
      </p>
    </div>
  </div>
)

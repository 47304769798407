import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Link from '../../components/Link'
import styles from './work.module.scss'

const title = 'Democratizing mental health care for kids'
const description =
  'Manatee partnered with us to get guidance around the complexity of turning an idea into a fully working product, and to create a strong visual language able to engage their unique audience – kids.'
const image = 'https://www.zero.london/case-manatee@3x.png'
const imageAlt = 'Manatee mobile app'
const imageDimensions = [2562, 1881]

export default ({ location }) => (
  <Layout location={location} back>
    <Helmet>
      <title>Democratizing mental health care for kids</title>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:image:alt' content={imageAlt} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:alt' content={imageAlt} />
      <meta property='og:image:width' content={imageDimensions[0]} />
      <meta property='og:image:height' content={imageDimensions[1]} />
    </Helmet>
    <div className={styles.Case}>
      <div className={styles.Intro}>
        <div className={styles.Screenshots}>
          <div className={styles.ImageManatee}>
            <img
              className={styles.desktop}
              alt='Manatee mobile app screenshots'
              srcSet={`
                /case-manatee.png 1x,
                /case-manatee@2x.png 2x,
                /case-manatee@3x.png 3x
              `.replace(/\n/g, ' ')}
            />
            <img
              className={styles.mobile}
              alt='Manatee mobile app screenshots'
              srcSet={`
                /case-manatee-mobile.png 1x,
                /case-manatee-mobile@2x.png 2x,
                /case-manatee-mobile@3x.png 3x
              `.replace(/\n/g, ' ')}
            />
          </div>
        </div>
        <aside>Manatee</aside>
        <h1>Democratizing mental health care</h1>
      </div>

      <div className={styles.Content}>
        <div className={styles.Description}>
          <h2>Challenge</h2>
          <p>
            Manatee partnered with us to get guidance around the complexity of turning an idea into a fully working
            product, and to create a strong visual language able to engage their unique audience – kids.
          </p>
          <p>
            We helped Manatee to define, design and prototype a mobile application to empower professionals and families
            to help children affected by behavioral disorders, with the goal of democratizing mental health care for
            kids.
          </p>
          <p>
            The app interaction model is structured around two core workflows. The parents workflow enables them to
            create tasks, set rewards and track progress, as well as monitor overall engagement. The children workflow
            helps kids to receive therapy, collect points and redeem rewards. This happens using a fun and interactive
            experience.
          </p>

          <h2>Results</h2>
          <p>
            We enabled Manatee to bring their concept to life, start doing user research, get validation and pitch to
            multiple investors. The prototype was presented at a TEDxMileHigh event in US.
          </p>
        </div>

        <div className={styles.Specs}>
          <h3>Deliverables</h3>
          <p className={styles.small}>Product definition, Product design, UI development</p>

          <h3>Tech Stack</h3>
          <p className={styles.small}>React Native</p>

          <h3>Duration</h3>
          <p className={styles.small}>3 weeks</p>
        </div>

        <div className={styles.Quote}>
          <div className={styles.Photo}>
            <img className={styles.ImageOne} alt='Damayanti Dipayana' src='/damayanti-dipayana.jpg' />
            <aside className={styles.mobile}>Damayanti Dipayana – Founder & CEO, Manatee</aside>
          </div>
          <div>
            <p>
              “Anyone would be lucky to work with the Zero team. Without any back and forth, they understood my vision,
              executed to perfection, all within really tight deadlines. Recommending them is a no brainer.”
            </p>
            <aside className={styles.desktop}>Damayanti Dipayana – Founder & CEO, Manatee</aside>
          </div>
        </div>

        <div className={styles.Next}>
          <h2>Next</h2>
          <Link to='/work/catapult-ecommerce' monochrome>
            <strong>Catapult</strong> – Help Catapult meet their revenue goals
          </Link>
        </div>
      </div>
    </div>
    <div className={styles.Contact}>
      <p>
        Need an opinion of your project?{' '}
        <Link button small to='/contact'>
          Let’s talk
        </Link>
      </p>
    </div>
    <div className={styles.FinalSection}>
      <h4>Are you an ambitious entrepreneur or business leader?</h4>
      <Link large button to='/contact'>
        Get our view on your project
      </Link>
    </div>
  </Layout>
)

import React from 'react'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import Link from './Link'
import { StaticQuery, Link as VanillaLink, graphql } from 'gatsby'
import Footer from './Footer'
import styles from './Layout.module.scss'

const name = 'Zero Technology'
const title = 'Zero Technology – Product Development Agency'
const description = 'Founders and business leaders trust us to create their digital products and ventures.'

const query = graphql`
  query {
    site {
      siteMetadata {
        twitter
        medium
        siteUrl
      }
    }
  }
`

function Layout({ back, home, location, footer, children }) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const { siteMetadata } = data.site
        const { twitter, medium } = siteMetadata

        return (
          <>
            <Helmet defaultTitle={title} titleTemplate={`${name} | %s`}>
              <meta name='description' content={description} />
              <meta name='keyword' content='software development, innovation, full stack' />
              <meta name='twitter:site' content={`@${twitter}`} />
              <meta property='og:type' content='website' />
              <meta property='og:site_name' content={name} />
              <link rel='canonical' href={`${data.site.siteMetadata.siteUrl}${location.pathname}`} />
              <html lang='en' />
            </Helmet>

            <div className={styles.App}>
              <div className={classNames(styles.Header, home && styles.home)}>
                <div className={styles.Nav}>
                  {back && (
                    <Link to='/' reverse monochrome>
                      Back to home
                    </Link>
                  )}
                </div>
                <VanillaLink to='/' className={styles.Logo}>
                  zero
                </VanillaLink>
              </div>
              <div className={styles.Content}>{children}</div>
              {footer && <Footer twitter={twitter} medium={medium} />}
            </div>
          </>
        )
      }}
    />
  )
}

export default Layout

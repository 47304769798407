import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import './Link.scss'

export default props => (
  <Link
    to={props.to}
    className={classnames(
      'link',
      {
        'link--reverse': props.reverse,
        subtle: props.subtle,
        large: props.large,
        small: props.small,
        button: props.button,
        monochrome: props.monochrome
      },
      props.className
    )}
  >
    {props.reverse ? (
      <Fragment>
        <span className='link__arrow'>←&nbsp;</span>
        {props.children}
      </Fragment>
    ) : (
      <Fragment>
        {props.children}
        {!props.button && <span className='link__arrow'>&nbsp;→</span>}
      </Fragment>
    )}
  </Link>
)

export const ExternalLink = props => (
  <a href={props.to} className={classnames('link', { 'link--reverse': props.reverse }, props.className)}>
    {props.reverse ? (
      <Fragment>
        <span className='link__arrow'>←&nbsp;</span>
        {props.children}
      </Fragment>
    ) : (
      <Fragment>
        {props.children}
        <span className='link__arrow'>&nbsp;→</span>
      </Fragment>
    )}
  </a>
)
